<template>
  <section>
    <div :style="getElementStyle">
      <h1 v-if="data.heading_type == 'H1'" class="heading">{{ data.label }}</h1>
      <h2 v-if="data.heading_type == 'H2'" class="heading">{{ data.label }}</h2>
      <h3 v-if="data.heading_type == 'H3'" class="heading">{{ data.label }}</h3>
      <h4 v-if="data.heading_type == 'H4'" class="heading">{{ data.label }}</h4>
      <h5 v-if="data.heading_type == 'H5'" class="heading">{{ data.label }}</h5>
      <h6 v-if="data.heading_type == 'H6'" class="heading">{{ data.label }}</h6>
    </div>
    <span v-if="isActive" class="setting-icon" @click="openSettings">
      <i class="el-icon-s-tools" />
    </span>
  </section>
</template>

<script>
export default {
  name: "HeadingView",
  components: {},
  props: ["data", "value", "isActive", "isDataTableField"],
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getStyle() {
      return `height:${this.data.height - 10}px`;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.style &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.style &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
      }
      borderStyle += ";text-align:" + this.inputTextAlignmentValue + ";";
      return borderStyle;
    },
  },
  data() {
    return {
      validations: [],
      inputTextAlignmentValue: "left",
    };
  },
  mounted() {
    // this.data.heading_type = "H4";
  },
  methods: {
    openSettings() {
      this.$emit("settings");
    },
  },
  watch: {
    "data.styles.inputTextAlignments": {
      handler(newVal) {
        // Whenever inputTextAlignments changes, update the computed property.
        this.inputTextAlignmentValue = newVal;
      },
      immediate: true, // This will trigger the handler immediately when the component is created
    },
  },
};
</script>

<style lang="scss"></style>
